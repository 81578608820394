.edit-form {
  margin: 0 auto;
  width: 100%;
}
.edit-form .el-form-item,
  .edit-form .inline-div {
    margin: auto;
    margin-top: 20px;
}
.edit-form .el-input__inner {
    border-color: #f0f2f5 !important;
}
.edit-form .block-item {
    width: 768px;
}
.edit-form .block-item .el-form-item__content {
      width: 530px;
}
.edit-form .block-item .el-select {
      width: 100%;
}
.pre,
.next {
  outline: none;
  border: none;
}
@media (max-width: 768px) {
.edit-form .block-item {
    width: auto;
}
.edit-form .block-item .el-form-item__content {
      width: auto;
}
.edit-form .block-item .el-select {
      width: 100%;
}
}
